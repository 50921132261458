import * as React from "react"
import styled from 'styled-components'
import '../style.scss'
import ljibbe from '../images/ljibbe.jpg'
import { GlobalStyle } from "../components/styles/GlobalStyle";


const CenteredDiv = styled.div`
  font-size: 1.5em;
  color: #6c6c6c;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column; // vertical aligned
`;

const Logo = styled.img`
  margin-bottom: 20px;
  border-radius: 50%; // round
`;

const IndexPage = () => {
  return (
      <main>
          <GlobalStyle />
        <title>Sljocht.nl - Ljibbe van der let</title>
        <CenteredDiv>
            <Logo src={ljibbe} />
            Sljocht.nl - Fotografie
        </CenteredDiv>
      </main>
  )
}

export default IndexPage
